import { PublicKey } from '@solana/web3.js';
import { RaffleMetaData } from '../lib/types';
import { TESTING } from './misc';

const testWhitelist = new Map<string, RaffleMetaData>([
 [
    'D6JLiSFeXGvruVgNWr2zQzdoyrGvvoda946qcR19on8y',
    {
      name: 'gangsta goonz #1157',
      overviewImageUri: '/resources/gg1.png',
    },
  ],
  [
    '6MPPQiFKbJajBzw5GLMcB57HZFHTLiZ5u2gordBAZatt',
    {
      name: 'SkelliesDAO',
      overviewImageUri: '/resources/thumb.png',
    },
  ],
]);

const prodWhitelist = new Map<string, RaffleMetaData>([

    [
    '3L5hA66UppbuFqnQfmZB9VgfFNSpXiC5TU49DeP9gGz5',
    {
      name: 'Entreprenerdz #2734',
      overviewImageUri: '/resources/2734.jpg',
    },
  ],
   [
    'JDwZZyaxA4K2zykbtFB1ZRBznWevG3jS9ZxZ4zKmvDBP',
    {
      name: 'Infected Rascals #4081',
      overviewImageUri: '/resources/ir.png',
    },
  ],
   [
    '6AyjCxaNPptW11yuDNQtxJpk3fh9sGwSLcwD3QHmR4LH',
    {
      name: 'TittyDAO Pass #1589',
      overviewImageUri: '/resources/tit.png',
    },
  ],
   [
    '37zgWUMpfaj8GqorDc7w9cZ16eVWD1r1pf2oPqNmEtdQ',
    {
      name: 'Futures Trader',
      overviewImageUri: '/resources/future.png',
    },
  ],
  
  
]);


export const RAFFLES_WHITELIST = TESTING ? testWhitelist : prodWhitelist;
