export const wrappedSOL = 'So11111111111111111111111111111111111111112';

const tokenRegistry = {
  So11111111111111111111111111111111111111112: {
    chainId: 101,
    address: wrappedSOL,
    symbol: 'SOL',
    name: 'SOL',
    decimals: 9,
    logoURI:
      'https://raw.githubusercontent.com/solana-labs/token-list/main/assets/mainnet/So11111111111111111111111111111111111111112/logo.png',
    tags: [],
    extensions: {
      website: 'https://solana.com/',
      serumV3Usdc: '9wFFyRfZBsuAha4YcuxcXLKwMxJR43S7fPfQLusDBzvT',
      serumV3Usdt: 'HWHvQhFmJB3NUcu1aihKmrKegfVxBEHzwVX6yZCKEsi1',
      coingeckoId: 'solana',
      imageURI: '/resources/solana-logo.gif',
    },
  },
  zRpVjG5wMWrNhpTtSiGMz9iBaMTQDdaVGCFLmYqCs4U: {
    chainId: 101,
    address: 'zRpVjG5wMWrNhpTtSiGMz9iBaMTQDdaVGCFLmYqCs4U',
    symbol: 'TT',
    name: 'TEST TOKEN',
    decimals: 6,
    logoURI:
      'https://raw.githubusercontent.com/solana-labs/token-list/main/assets/mainnet/9nusLQeFKiocswDt6NQsiErm1M43H2b8x6v5onhivqKv/logo.png',
    tags: [],
    extensions: {
      website: 'https://sollama.finance',
      twitter: 'https://twitter.com/SollamaFinance',
    },
  },

  H32RbcbAoskfMVyPSTNQucEkYP2qvYLkmDg5ij4cPBhH: {
    chainId: 101,
    address: 'H32RbcbAoskfMVyPSTNQucEkYP2qvYLkmDg5ij4cPBhH',
    symbol: 'PM2',
    name: 'Prize Mint 2',
    decimals: 0,
    logoURI:
      'https://raw.githubusercontent.com/solana-labs/token-list/main/assets/mainnet/9nusLQeFKiocswDt6NQsiErm1M43H2b8x6v5onhivqKv/logo.png',
    tags: [],
    extensions: {
      website: 'https://sollama.finance',
      twitter: 'https://twitter.com/SollamaFinance',
    },
  },
  DCTo8EdRrycGpjRDntmdAMoaHdq77mQwdEchtoXAtje3: {
    chainId: 101,
    address: 'DCTo8EdRrycGpjRDntmdAMoaHdq77mQwdEchtoXAtje3',
    symbol: 'DCT',
    name: 'dRaffle Community Token',
    decimals: 6,
    logoURI: '/dRaffle-logo.png',
    tags: []
  },
  '3BDomg3ieBauB8tXPibRTeQiHccCbGmqoKMSZBKVxj9e': {
    chainId: 101,
    address: '9nKSD7YtExybAJRo8tXY6nYxxbqnL8vZyT17RzTgZRmb',
    symbol: 'ARTIST ENTRY',
    name: 'Artist Choice 1/1 Entry',
    decimals: 0,
    logoURI: 'https://arweave.net/myfeY108R-b96FZHqe16tJIdmmShrbiKkIhFnrn9_BE?ext=png',
    tags: []
  },
    'timeYznxbfZUWAERs1M7bgVGnCPUKNJ1YujQRXyjZ5a': {
    chainId: 101,
    address: 'timeYznxbfZUWAERs1M7bgVGnCPUKNJ1YujQRXyjZ5a',
    symbol: 'TIME',
    name: 'Time',
    decimals: 2,
    logoURI: '/resources/time.png',
    tags: []
  },
   'JoKerH31aabWShTZbLo6A6rWBHSM9wSUFoVufV2WMmn': {
    chainId: 101,
    address: 'JoKerH31aabWShTZbLo6A6rWBHSM9wSUFoVufV2WMmn',
    symbol: 'JOKER',
    name: 'Joker',
    decimals: 6,
    logoURI: '/resources/joker.png',
    tags: []
  }
  
};

export const tokenInfoMap = new Map(Object.entries(tokenRegistry));

export const UNKNOWN_TOKEN_INFO = {
  chainId: 101,
  symbol: '???',
  name: 'Unkown token',
  decimals: 0,
  logoURI:
    'https://raw.githubusercontent.com/solana-labs/token-list/main/assets/mainnet/9nusLQeFKiocswDt6NQsiErm1M43H2b8x6v5onhivqKv/logo.png',
  tags: [],
  extensions: {},
};
